<script>
  import Class from '../../internal/Class';

  let klass = '';
  export let cols = false;
  export let sm = false;
  export let md = false;
  export let lg = false;
  export let xl = false;
  export let offset = false;
  export let offset_sm = false;
  export let offset_md = false;
  export let offset_lg = false;
  export let offset_xl = false;
  export let style = null;
  export { klass as class };
</script>

<style lang="scss" src="./Col.scss" global>/* prettier-ignore */
/*
Usage:
@include create_breakpoints() using ($screen_size) {
  .class-screen_size {property: value}
}
*/
/* prettier-ignore */
:global(.s-col) {
  width: 100%;
  padding: 12px;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
:global(.s-col.col-auto) {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
:global(.s-col.col-1) {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}
:global(.s-col.col-2) {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}
:global(.s-col.col-3) {
  flex: 0 0 25%;
  max-width: 25%;
}
:global(.s-col.col-4) {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
:global(.s-col.col-5) {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}
:global(.s-col.col-6) {
  flex: 0 0 50%;
  max-width: 50%;
}
:global(.s-col.col-7) {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}
:global(.s-col.col-8) {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}
:global(.s-col.col-9) {
  flex: 0 0 75%;
  max-width: 75%;
}
:global(.s-col.col-10) {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}
:global(.s-col.col-11) {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}
:global(.s-col.col-12) {
  flex: 0 0 100%;
  max-width: 100%;
}
:global(.s-col.offset-1) {
  margin-left: 8.3333333333%;
}
:global(.s-col.offset-2) {
  margin-left: 16.6666666667%;
}
:global(.s-col.offset-3) {
  margin-left: 25%;
}
:global(.s-col.offset-4) {
  margin-left: 33.3333333333%;
}
:global(.s-col.offset-5) {
  margin-left: 41.6666666667%;
}
:global(.s-col.offset-6) {
  margin-left: 50%;
}
:global(.s-col.offset-7) {
  margin-left: 58.3333333333%;
}
:global(.s-col.offset-8) {
  margin-left: 66.6666666667%;
}
:global(.s-col.offset-9) {
  margin-left: 75%;
}
:global(.s-col.offset-10) {
  margin-left: 83.3333333333%;
}
:global(.s-col.offset-11) {
  margin-left: 91.6666666667%;
}
@media only screen and (min-width: 600px) {
  :global(.s-col.sm-auto) {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  :global(.s-col.sm-1) {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  :global(.s-col.sm-2) {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  :global(.s-col.sm-3) {
    flex: 0 0 25%;
    max-width: 25%;
  }
  :global(.s-col.sm-4) {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  :global(.s-col.sm-5) {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  :global(.s-col.sm-6) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  :global(.s-col.sm-7) {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  :global(.s-col.sm-8) {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  :global(.s-col.sm-9) {
    flex: 0 0 75%;
    max-width: 75%;
  }
  :global(.s-col.sm-10) {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  :global(.s-col.sm-11) {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  :global(.s-col.sm-12) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  :global(.s-col.offset-sm-0) {
    margin-left: 0;
  }
  :global(.s-col.offset-sm-1) {
    margin-left: 8.3333333333%;
  }
  :global(.s-col.offset-sm-2) {
    margin-left: 16.6666666667%;
  }
  :global(.s-col.offset-sm-3) {
    margin-left: 25%;
  }
  :global(.s-col.offset-sm-4) {
    margin-left: 33.3333333333%;
  }
  :global(.s-col.offset-sm-5) {
    margin-left: 41.6666666667%;
  }
  :global(.s-col.offset-sm-6) {
    margin-left: 50%;
  }
  :global(.s-col.offset-sm-7) {
    margin-left: 58.3333333333%;
  }
  :global(.s-col.offset-sm-8) {
    margin-left: 66.6666666667%;
  }
  :global(.s-col.offset-sm-9) {
    margin-left: 75%;
  }
  :global(.s-col.offset-sm-10) {
    margin-left: 83.3333333333%;
  }
  :global(.s-col.offset-sm-11) {
    margin-left: 91.6666666667%;
  }
}
@media only screen and (min-width: 960px) {
  :global(.s-col.md-auto) {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  :global(.s-col.md-1) {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  :global(.s-col.md-2) {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  :global(.s-col.md-3) {
    flex: 0 0 25%;
    max-width: 25%;
  }
  :global(.s-col.md-4) {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  :global(.s-col.md-5) {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  :global(.s-col.md-6) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  :global(.s-col.md-7) {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  :global(.s-col.md-8) {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  :global(.s-col.md-9) {
    flex: 0 0 75%;
    max-width: 75%;
  }
  :global(.s-col.md-10) {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  :global(.s-col.md-11) {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  :global(.s-col.md-12) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  :global(.s-col.offset-md-0) {
    margin-left: 0;
  }
  :global(.s-col.offset-md-1) {
    margin-left: 8.3333333333%;
  }
  :global(.s-col.offset-md-2) {
    margin-left: 16.6666666667%;
  }
  :global(.s-col.offset-md-3) {
    margin-left: 25%;
  }
  :global(.s-col.offset-md-4) {
    margin-left: 33.3333333333%;
  }
  :global(.s-col.offset-md-5) {
    margin-left: 41.6666666667%;
  }
  :global(.s-col.offset-md-6) {
    margin-left: 50%;
  }
  :global(.s-col.offset-md-7) {
    margin-left: 58.3333333333%;
  }
  :global(.s-col.offset-md-8) {
    margin-left: 66.6666666667%;
  }
  :global(.s-col.offset-md-9) {
    margin-left: 75%;
  }
  :global(.s-col.offset-md-10) {
    margin-left: 83.3333333333%;
  }
  :global(.s-col.offset-md-11) {
    margin-left: 91.6666666667%;
  }
}
@media only screen and (min-width: 1264px) {
  :global(.s-col.lg-auto) {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  :global(.s-col.lg-1) {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  :global(.s-col.lg-2) {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  :global(.s-col.lg-3) {
    flex: 0 0 25%;
    max-width: 25%;
  }
  :global(.s-col.lg-4) {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  :global(.s-col.lg-5) {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  :global(.s-col.lg-6) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  :global(.s-col.lg-7) {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  :global(.s-col.lg-8) {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  :global(.s-col.lg-9) {
    flex: 0 0 75%;
    max-width: 75%;
  }
  :global(.s-col.lg-10) {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  :global(.s-col.lg-11) {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  :global(.s-col.lg-12) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  :global(.s-col.offset-lg-0) {
    margin-left: 0;
  }
  :global(.s-col.offset-lg-1) {
    margin-left: 8.3333333333%;
  }
  :global(.s-col.offset-lg-2) {
    margin-left: 16.6666666667%;
  }
  :global(.s-col.offset-lg-3) {
    margin-left: 25%;
  }
  :global(.s-col.offset-lg-4) {
    margin-left: 33.3333333333%;
  }
  :global(.s-col.offset-lg-5) {
    margin-left: 41.6666666667%;
  }
  :global(.s-col.offset-lg-6) {
    margin-left: 50%;
  }
  :global(.s-col.offset-lg-7) {
    margin-left: 58.3333333333%;
  }
  :global(.s-col.offset-lg-8) {
    margin-left: 66.6666666667%;
  }
  :global(.s-col.offset-lg-9) {
    margin-left: 75%;
  }
  :global(.s-col.offset-lg-10) {
    margin-left: 83.3333333333%;
  }
  :global(.s-col.offset-lg-11) {
    margin-left: 91.6666666667%;
  }
}
@media only screen and (min-width: 1904px) {
  :global(.s-col.xl-auto) {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  :global(.s-col.xl-1) {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  :global(.s-col.xl-2) {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  :global(.s-col.xl-3) {
    flex: 0 0 25%;
    max-width: 25%;
  }
  :global(.s-col.xl-4) {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  :global(.s-col.xl-5) {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  :global(.s-col.xl-6) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  :global(.s-col.xl-7) {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  :global(.s-col.xl-8) {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  :global(.s-col.xl-9) {
    flex: 0 0 75%;
    max-width: 75%;
  }
  :global(.s-col.xl-10) {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  :global(.s-col.xl-11) {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  :global(.s-col.xl-12) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  :global(.s-col.offset-xl-0) {
    margin-left: 0;
  }
  :global(.s-col.offset-xl-1) {
    margin-left: 8.3333333333%;
  }
  :global(.s-col.offset-xl-2) {
    margin-left: 16.6666666667%;
  }
  :global(.s-col.offset-xl-3) {
    margin-left: 25%;
  }
  :global(.s-col.offset-xl-4) {
    margin-left: 33.3333333333%;
  }
  :global(.s-col.offset-xl-5) {
    margin-left: 41.6666666667%;
  }
  :global(.s-col.offset-xl-6) {
    margin-left: 50%;
  }
  :global(.s-col.offset-xl-7) {
    margin-left: 58.3333333333%;
  }
  :global(.s-col.offset-xl-8) {
    margin-left: 66.6666666667%;
  }
  :global(.s-col.offset-xl-9) {
    margin-left: 75%;
  }
  :global(.s-col.offset-xl-10) {
    margin-left: 83.3333333333%;
  }
  :global(.s-col.offset-xl-11) {
    margin-left: 91.6666666667%;
  }
}</style>

<div
  class="s-col {klass}"
  use:Class={[cols && `col-${cols}`, sm && `sm-${sm}`, md && `md-${md}`, lg && `lg-${lg}`, xl && `xl-${xl}`, offset && `offset-${offset}`, offset_sm && `offset-sm-${offset_sm}`, offset_md && `offset-md-${offset_md}`, offset_lg && `offset-lg-${offset_lg}`, offset_xl && `offset-xl-${offset_xl}`]}
  {style}>
  <slot />
</div>
