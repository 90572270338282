<script>
  import TextColor from '../../internal/TextColor';

  let klass = '';
  export { klass as class };
  export let color = null;
  export let dense = false;
  export let readonly = false;
  export let disabled = false;
  export let error = false;
  export let success = false;
  export let style = null;
</script>

<style lang="scss" global>/* prettier-ignore */
:global(.s-text-field__input) {
  display: flex;
  flex-grow: 1;
}
:global(.s-text-field__input) :global(label) {
  position: absolute;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  top: 6px;
  pointer-events: none;
  transform-origin: top left;
}
:global(.s-text-field__input) :global(label.active) {
  max-width: 133%;
  transform: translateY(-18px) scale(0.75);
}
:global(.s-text-field__input) :global(input) {
  caret-color: inherit;
  flex: 1 1 auto;
  line-height: 20px;
  padding: 8px 0 8px;
  max-width: 100%;
  min-width: 0;
  width: 100%;
}

:global(.s-text-field__wrapper) {
  width: 100%;
  color: inherit;
  caret-color: currentColor;
  display: flex;
  align-items: center;
}
:global(.s-text-field__wrapper::before), :global(.s-text-field__wrapper::after) {
  border-radius: inherit;
  width: inherit;
  bottom: -1px;
  content: "";
  left: 0;
  position: absolute;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  pointer-events: none;
}
:global(.s-text-field__wrapper::before) {
  border-color: var(--theme-text-fields-border);
  border-style: solid;
  border-width: thin 0 0 0;
}
:global(.s-text-field__wrapper::after) {
  background-color: currentColor;
  border-color: currentColor;
  border-style: solid;
  border-width: thin 0 thin 0;
  transform: scaleX(0);
}
:global(.s-text-field__wrapper:hover::before) {
  border-color: var(--theme-text-primary);
}
:global(.s-text-field__wrapper:focus-within::after) {
  transform: scale(1);
}
:global(.s-text-field__wrapper:focus-within) :global(label) {
  color: inherit;
}
:global(.s-text-field__wrapper) > :global([slot=prepend]) {
  margin-right: 8px;
}
:global(.s-text-field__wrapper) > :global([slot=append]) {
  margin-right: 8px;
}
:global(.s-text-field__wrapper.outlined::before) {
  top: 0;
  border-width: thin;
}
:global(.s-text-field__wrapper.outlined:focus-within::before) {
  border-color: currentColor;
  border-width: 2px;
}
:global(.s-text-field__wrapper.outlined) :global(label) {
  top: 18px;
}
:global(.s-text-field__wrapper.outlined) :global(label.active) {
  padding: 0 4px;
  background-color: var(--theme-surface);
  transform: translateY(-24px) scale(0.75);
}
:global(.s-text-field__wrapper.outlined), :global(.s-text-field__wrapper.solo), :global(.s-text-field__wrapper.filled) {
  padding: 0 1px 0 12px;
}
:global(.s-text-field__wrapper.filled), :global(.s-text-field__wrapper.outlined) {
  min-height: 56px;
}
:global(.s-text-field__wrapper.filled) {
  border-radius: 4px 4px 0 0;
  background-color: var(--theme-text-fields-filled);
}
:global(.s-text-field__wrapper.filled:hover) {
  background-color: var(--theme-text-fields-filled-hover);
}
:global(.s-text-field__wrapper.filled) :global(input),
:global(.s-text-field__wrapper.filled) :global(textarea) {
  padding-top: 22px;
}
:global(.s-text-field__wrapper.filled) :global(label) {
  top: 20px;
}
:global(.s-text-field__wrapper.filled) :global(label.active) {
  transform: translateY(-10px) scale(0.75);
}
:global(.s-text-field__wrapper.outlined::after), :global(.s-text-field__wrapper.solo::after), :global(.s-text-field__wrapper.rounded::after) {
  display: none;
}
:global(.s-text-field__wrapper.outlined), :global(.s-text-field__wrapper.solo) {
  border-radius: 4px;
}
:global(.s-text-field__wrapper.solo) {
  min-height: 48px;
  /* prettier-ignore */
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
:global(.s-text-field__wrapper.solo::before) {
  display: none;
}
:global(.s-text-field__wrapper.rounded) {
  border-radius: 28px;
}
:global(.s-text-field__wrapper.rounded.filled::before) {
  border: none;
}
:global(.s-text-field__wrapper.rounded.outlined) {
  padding: 0 24px;
}
:global(.s-text-field__wrapper.flat) {
  /* prettier-ignore */
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12) !important;
}

:global(.s-text-field.error) :global(.s-text-field__wrapper::before), :global(.s-text-field.success) :global(.s-text-field__wrapper::before) {
  border-color: currentColor !important;
}
:global(.s-text-field.dense) :global(input) {
  padding: 4px 0 2px;
}
:global(.s-text-field.dense) :global(.s-text-field__wrapper.outlined), :global(.s-text-field.dense) :global(.s-text-field__wrapper.filled) {
  min-height: 40px;
}
:global(.s-text-field.dense) :global(.s-text-field__wrapper.outlined) :global(label) {
  top: 10px;
}
:global(.s-text-field.dense) :global(.s-text-field__wrapper.outlined) :global(label.active) {
  transform: translateY(-16px) scale(0.75);
}
:global(.s-text-field.dense) :global(.s-text-field__wrapper.solo) {
  min-height: 40px;
}
:global(.s-text-field.dense) :global(.s-text-field__wrapper.filled) :global(input),
:global(.s-text-field.dense) :global(.s-text-field__wrapper.filled) :global(textarea) {
  margin-top: 11px;
}
:global(.s-text-field.dense) :global(.s-text-field__wrapper.filled) :global(label) {
  top: 12px;
}
:global(.s-text-field.dense) :global(.s-text-field__wrapper.filled) :global(label.active) {
  transform: translateY(-8px) scale(0.75);
}

/* prettier-ignore */
:global(.s-textarea) :global(textarea) {
  align-self: stretch;
  flex: 1 1 auto;
  line-height: 1.75rem;
  max-width: 100%;
  min-height: 32px;
  outline: none;
  padding: 0;
  width: 100%;
  margin-top: 4px;
}
:global(.s-textarea) :global(.s-text-field__wrapper) > :global([slot=prepend]),
:global(.s-textarea) :global(.s-text-field__wrapper) > :global([slot=append]) {
  align-self: flex-start;
  margin-top: 2px;
}
:global(.s-textarea) :global(.s-text-field__wrapper.filled) :global([slot=prepend]),
:global(.s-textarea) :global(.s-text-field__wrapper.filled) :global([slot=append]) {
  margin-top: 28px;
}
:global(.s-textarea) :global(.s-text-field__wrapper.outlined) :global([slot=prepend]),
:global(.s-textarea) :global(.s-text-field__wrapper.outlined) :global([slot=append]) {
  margin-top: 20px;
}
:global(.s-textarea) :global(.s-text-field__wrapper.outlined) :global(textarea) {
  margin-top: 18px;
}
:global(.s-textarea) :global(.s-text-field__wrapper.solo) :global([slot=prepend]),
:global(.s-textarea) :global(.s-text-field__wrapper.solo) :global([slot=append]) {
  margin-top: 8px;
}
:global(.s-textarea) :global(.s-text-field__wrapper.solo) :global(textarea) {
  margin-top: 8px;
}
:global(.s-textarea) :global(.s-text-field__wrapper.autogrow) :global(textarea) {
  overflow: hidden;
}
:global(.s-textarea) :global(.s-text-field__wrapper.no-resize) :global(textarea) {
  resize: none;
}

/* prettier-ignore */
:global(.s-input__slot) {
  border-radius: inherit;
  align-items: center;
  color: inherit;
  display: flex;
  margin-bottom: 8px;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
  height: inherit;
}

:global(.s-input__control) {
  display: flex;
  flex-direction: column;
  width: 100%;
}

:global(.s-input__details) {
  color: var(--theme-text-secondary);
  display: flex;
  flex: 1 0 auto;
  max-width: 100%;
  font-size: 12px;
  overflow: hidden;
}
:global(.s-input__details) > :global([slot=messages]) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
}

:global(.s-input) {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex: 1 1 auto;
  font-size: 16px;
  letter-spacing: normal;
  max-width: 100%;
  text-align: left;
}
:global(.s-input) :global(input) {
  max-height: 32px;
}
:global(.s-input) :global(input),
:global(.s-input) :global(textarea) {
  color: var(--theme-text-primary);
}
:global(.s-input) :global(input:invalid),
:global(.s-input) :global(textarea:invalid) {
  box-shadow: none;
}
:global(.s-input) :global(input:focus), :global(.s-input) :global(input:active), :global(.s-input) :global(textarea:focus), :global(.s-input) :global(textarea:active) {
  outline: none;
}
:global(.s-input) :global(input::placeholder),
:global(.s-input) :global(textarea::placeholder) {
  color: var(--theme-text-disabled);
}
:global(.s-input) > :global([slot=prepend-outer]),
:global(.s-input) > :global([slot=append-outer]) {
  align-self: flex-start;
  display: inline-flex;
  margin-bottom: 4px;
  margin-top: 4px;
  line-height: 1;
}
:global(.s-input) > :global([slot=prepend-outer]) {
  margin-right: 9px;
}
:global(.s-input) > :global([slot=append-outer]) {
  margin-left: 9px;
}
:global(.s-input) :global(.s-icon) {
  user-select: none;
  align-items: center;
  display: inline-flex;
  height: 24px;
  flex: 1 0 auto;
  justify-content: center;
  min-width: 24px;
  width: 24px;
}
:global(.s-input) :global(label) {
  height: 20px;
  line-height: 20px;
  color: var(--theme-text-secondary);
  white-space: nowrap;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
:global(.s-input:focus-within) :global(.s-icon) {
  color: inherit;
}
:global(.s-input.disabled) {
  pointer-events: none;
  color: var(--theme-text-disabled);
}
:global(.s-input.disabled) :global(input),
:global(.s-input.disabled) :global(textarea) {
  color: var(--theme-text-disabled);
}
:global(.s-input.disabled) :global(.s-icon) {
  color: var(--theme-icons-inactive);
}
:global(.s-input.disabled) :global(label) {
  color: var(--theme-text-disabled);
}
:global(.s-input.dense) :global(.s-input__slot) {
  margin-bottom: 4px;
}</style>

<div
  class="s-input {klass}"
  class:dense
  class:error
  class:success
  class:readonly
  class:disabled
  {style}
  use:TextColor={success ? 'success' : error ? 'error' : color}>
  <slot name="prepend-outer" />
  <div class="s-input__control">
    <div class="s-input__slot">
      <slot />
    </div>
    <div class="s-input__details">
      <slot name="messages" />
    </div>
  </div>
  <slot name="append-outer" />
</div>
